import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";

interface IPopupProps {
    title: string | ReactNode
    show: boolean
    onHide: () => void
    size?: 'sm' | 'lg' | 'xl';
    children: any
}

interface IBodyProps {
    children: ReactNode | ReactNode[];
}

interface IFooterProps {
    children: ReactNode | ReactNode[];
}

const Popup = (props: IPopupProps) => {
    let subComponentList = Object.keys(Popup)
    let subComponents = subComponentList.map((key) => {
        return React.Children.map(props.children, (child) => {
            if (child && child.type)
                return child.type._type === key ? child : null
        });
    });

    return (
        <Modal show={props.show} onHide={props.onHide} size={props.size}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            {subComponents.map((component) => component)}
        </Modal>
    );
}

const Body = (props: IBodyProps) => <Modal.Body>{props.children}</Modal.Body>;
Body._type = 'Body'
Popup.Body = Body;

const Footer = (props: IFooterProps) => <Modal.Footer>{props.children}</Modal.Footer>;
Footer._type = 'Footer'
Popup.Footer = Footer;

export default Popup;
