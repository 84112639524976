import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import Login from "./components/Login";
import ProjectsView from "./components/Projects.tsx";
import SkillsView from "./components/Skills";
import ProfileNl from "./views/Profile-nl";

const AppRoutes = [
    { index: true, element: <Home /> },
    { path: '/login', element: <Login /> },
    { path: '/nl/projects', element: <ProjectsView /> },
    { path: '/nl/skills', element: <SkillsView /> },
    { path: '/nl/profile', element: <ProfileNl/>},

    { path: '/counter', element: <Counter /> },
    { path: '/fetch-data', element: <FetchData /> }
];

export default AppRoutes;
