import React, { useEffect, useState } from 'react';
import IProject from '../models/project';
import DataService from "./../data.service";
import ProjectPopup from "./ProjectPopup";
import { Container } from 'reactstrap';

const ProjectsView: React.FC = () => {
    const [projects, setProjects] = useState<IProject[]>([]);
    const [project, setProject] = useState<IProject>();
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        DataService
            .get<IProject[]>('projects')
            .then(response => setProjects(response))
            .catch(e => console.log(e));
    }, [])

    function showProject(project: IProject) {
        setProject(project);
        setShowPopup(true);
    }

    return (
        <Container className='content'>
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th style={{ width: '25%' }}></th>
                        <th style={{ width: '15%' }}>Klant</th>
                        <th style={{ width: '30%' }}>Rol</th>
                        <th style={{ width: '15%' }}>Start</th>
                        <th style={{ width: '15%' }}>Duur</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map(project =>
                        <tr key={project.klant} onClick={() => showProject(project)}>
                            <td><img style={{ height: 25, maxWidth: '100%' }} src={project.logo} /></td>
                            <td>{project.klant}</td>
                            <td>{project.rol}</td>
                            <td>{project.start}</td>
                            <td>{project.duur}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <ProjectPopup show={showPopup} onHide={() => setShowPopup(false)} project={project} />
        </Container>
    );
}
export default ProjectsView;