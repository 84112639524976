import React from 'react';
import { Container } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import xanderImg from '../assets/img/xander.jpg';

const Home: React.FC = () => {

    return (
        <>
            <div className="avatar" style={{ marginTop: 50, marginBottom: -64 }}>
                <img src={xanderImg} alt="avatar" />
            </div>
            <Container className='content' style={{ overflowY: 'auto', paddingLeft: 30, paddingRight: 30, paddingTop: 128, marginTop: 0, marginBottom: 10, maxHeight: 'none' }}>

                <h3 className="card-title">Xander Bommelé</h3>
                <h6 className="category text-gray">Freelance Senior .net web developer (full stack)</h6>
                <h3 style={{ marginTop: 50 }}>Wie ben ik?</h3>
                <p className="card-description">
                    Met ruim 25 jaar programeer ervaring ben ik een zeer ervaren allround full stack Microsoft .net web developer. Ik ben op mijn best in een uitdagende en dynamische omgeving. Als echte teamspeler ben ik stressbestendig, resultaat gericht, proactief, maar vooral pragmatisch. Ik weet hierbij een uitstekend de balans te vinden tussen technische perfectie en (commerciële) haalbaarheid.
                    Als lead developer hou ik van eenvoudige en goed te onderhouden code door toepassing van SOLID en DRY design principes en design patterns. De wat meer senior ontwikkelaars betrek ik in technisch complexe vraagstukken. Hierdoor weet ik een hecht team te creëren.
                </p>
                <h3 style={{ marginTop: 50 }} >Wat vind ik belangrijk?</h3>
                <p className="card-description">
                    Kwaliteit staat bij mij hoog in het vaandel. Een softwareproduct maken die aan alle verwachtingen van de gebruiker voldoet staat daarbij centraal. Dit betekent dus niet alleen dat alle requirements juist zijn geïmplementeerd, maar denk daarbij ook aan zaken die meestal niet expliciet genoemd worden zoals performance, schaalbaarheid, onderhoudbaarheid en beveiliging van de applicatie.
                    Uitgangspunt voor het maken van kwalitatief goede software is voor mij het maken van een goed design, hierbij pas ik design princiepen zoals o.a. SOLID en DRY toe.
                    Om de kwaliteit te borgen vind ik het belangrijk om unittests te schrijven. Ook hier heb ik ruime ervaring mee.
                </p>
                <p className="card-description">
                    Wat ik ook belangrijk vind is het team waarmee we de applicatie maken. Klaar staan voor elkaar en als team klaar staan voor de gebruikers.
                </p>
                <h3>Waar ben ik goed in?</h3>
                <p className="card-description">
                    Een van mijn sterke kanten is het analyseren van problemen; doordringen tot de kern en vervolgens een goed design maken die het probleem echt oplost.
                </p>
                <p className="card-description">
                    Waar ik altijd voor gewaardeerd word is mijn manier van communiceren. Die is open en helder en aangepast op de ontvanger. Daardoor kan ik goed met alle stakeholders overweg van eindgebruikers en klanten tot het hoogste management.
                </p>
                <h3 style={{ marginTop: 50 }}>Wat voor project zoek ik?</h3>
                <p className="card-description">
                    Ik hou van projecten met een uitdaging en ik werk graag met de laatste technologie. Ik heb zeer veel ervaring in het maken van backend software. Maar momenteel focus ik me meer op client-side technologieën zoals React.js.
                    Verder werk ik graag aan grote projecten die belangrijk zijn voor de business.
                </p>
                <div className='wrapper'><Button as="a" variant="success" href={"../files/CV Xander Bommelé.pdf"} type="button" download="CV Xander Bommelé.pdf" >Download CV</Button></div>
            </Container>
        </>
    );
}
export default Home;
