import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import ControlFeedback from "./controlfeedback";
import { ControlProps } from "./FieldTypes";

const PasswordControl: React.FC<ControlProps> = ({ name, value, disabled, width, validationResults, onChange, onBlur }) => {

    function onControlChange(e: ChangeEvent<HTMLInputElement>) {
        onChange(e.target.value);
    }

    let v = '';
    if (value)
        v = value;

    let isValid = validationResults && validationResults.length > 0 ? false : true;

    if (width === undefined)
        width = '100%';

    return (
        <div style={{ width: width }} className='field-control password'>
            <Form.Control type='password' name={name} value={v} disabled={disabled} onChange={onControlChange} isInvalid={!isValid} onBlur={onBlur} />
            <ControlFeedback isValid={isValid} validationResults={validationResults}></ControlFeedback>
        </div>
    );
}
export default PasswordControl;