import React from 'react'
import { FieldProps } from './FieldTypes'
import FieldLabel from './fieldlabel'
import FieldControl from './fieldcontrol'

export const Field: React.FC<FieldProps> = ({ controlProps, fieldType, visible, disabled, options, cols, rows }): JSX.Element | null => {

    if (visible === false)
        return null;

    //controlProps.labelProps = labelProps;

    return (
        <div id={`form-field-${controlProps.name}`} className={`form-field ${fieldType}`} >
            {fieldType != 'Checkbox' && <FieldLabel {...controlProps.labelProps} />}
            <FieldControl controlProps={controlProps} fieldType={fieldType} disabled={disabled} options={options} cols={cols} rows={rows}  />
        </div>
    )
}
export default Field
