import React from "react";
import { Form } from "react-bootstrap";
import { LabelProps } from "./FieldTypes";

const FieldLabel: React.FC<LabelProps> = ({ text, required, labelSize }) => {
    if (labelSize === 'auto') {
        return (
            <Form.Label column>
                {text}
                {required && <span className="required">*</span>}
            </Form.Label>
        );
    }

    let labelSm = 2;
    if (labelSize)
        labelSm = labelSize;

    return (
        <Form.Label column>
            {text}
            {required && <span className="required">*</span>}
        </Form.Label>
    );
}
export default FieldLabel;
