import React from "react";
import Popup from "./popup";
import IProject from "../models/project";

interface IProps {
    show: boolean
    onHide: () => void
    project?: IProject
}

const AddBomItemPopup: React.FC<IProps> = ({ show, onHide, project }) => {

    if (!project)
        return null;

    return (
        <Popup show={show} onHide={onHide} size={'xl'} title={
            <div style={{width: 1000}}>
                <div className="pull-left">
                    <div>{project.rol}</div>
                    <div style={{ fontSize: 18}}>{project.klant}</div>
                </div>
                <div className="pull-right">
                    <div><img style={{ height: 45 }} src={project.logo} /></div>
                    <div style={{ fontSize: 16, marginTop: 10 }}>{project.start} - {project.eind}</div>
                </div>
            </div>
        }>
            <Popup.Body>
                <div>
                    <p>{project.situatie}</p>
                    <table>
                        <tr><th style={{width:'150px'}}>Methodiek</th><td>{project.methodiek}</td></tr>
                        <tr><th>Ontwikkeltools</th><td>{project.tools}</td></tr>
                        <tr><th>Technologie</th><td>{project.technology}</td></tr>
                        <tr><th>Database</th><td>{project.database}</td></tr>
                    </table>
                </div>
            </Popup.Body>
        </Popup>
    );
}
export default AddBomItemPopup;