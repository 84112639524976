import React from 'react';
import bgImg from '../assets/img/backgrounds/bg37.jpg';
import NavMenuView from './NavMenu';
import { Container } from 'react-bootstrap';

interface Props {
    children: JSX.Element
}

const Layout: React.FC<Props> = ({ children }) => {

    return (
        <div className='background' style={{ backgroundImage: `url(${bgImg})` }}>
            <div className='backgroundOverlay'>
                <NavMenuView />
                <div className='content-container'>
                    {children}
                </div>
                <Container style={{ color: 'white' }}>
                    <div className="contact-details pr_contactus">
                        <h1>Contact</h1>
                        <div className="footer-contactus d-flex flex-row gap-3">
                            <i className="fa fa-location-dot white"></i>
                            <span>Achtmanstraat 17, 2645 MD, Delfgauw</span>
                        </div>
                        <div className="footer-contactus d-flex flex-row gap-3">
                            <i className="fa fa-phone white"></i>
                            <span>Telefoon: <a href="tel:+31619934366" style={{ color: '#3a7dff' }}>+31619934366</a></span>
                        </div>
                        <div className="footer-contactus d-flex flex-row gap-3">
                            <i className="fa fa-envelope white"></i>
                            <span>E-mail: <a href="mailto:xander@bommele.nl" style={{ color: '#3a7dff' }} >xander@bommele.nl</a> </span>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}
export default Layout
