import React from "react";
import { Form } from "react-bootstrap";

export interface FeedbackProps {
    validationResults?: string[]
    isValid?: boolean
}

const FieldLabel: React.FC<FeedbackProps> = ({ isValid, validationResults }) => {
    const validationError = validationResults ? validationResults.join(', ') : ''

    return (
        <Form.Control.Feedback type={isValid !== false  ? 'valid' : 'invalid'}>{validationError}</Form.Control.Feedback>
    );
}
export default FieldLabel;
