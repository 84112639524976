import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from "axios";
/*
import { Result, DataResult } from "./../types/Result";
import { AuthenticatedUserModel } from "../types/AuthenticatedUserModel";
import FileCreateResultModel from './../models/FileCreateResultModel';
*/

class DataService {
    getInstance(): AxiosInstance {
        let url = process.env.REACT_APP_API_ENDPOINT
        const instance = axios.create({
            baseURL: url,
        });

        /*
        instance.interceptors.response.use(
            (response) => { return response; },
            (error) => {
                //if (error.response.status === 401)
                    //Promise.reject(error);
            }
        );
        */

        return instance;
    }

    getHeaders(withFile?: boolean, responseType?: ResponseType): AxiosRequestConfig {
        var config: AxiosRequestConfig = {
            headers: {}, 
            responseType
        };

        if (withFile === true) {
            config.headers = { 'Content-type': 'multipart/form-data; boundary=###' };
        }
        else {
            config.headers = { 'Content-type': 'application/json' };
        }

        /*
        const sUser = localStorage.getItem('user');
        if (sUser != null) {
            const user: AuthenticatedUserModel = JSON.parse(sUser);
            if (user.token) {
                config.headers.Authorization = 'Bearer ' + user.token;
            }
        }
        */

        return config;
    }
    async get<T>(url: string): Promise<T> {
        let response = await this.getInstance().get<T>(url, this.getHeaders());
        return response.data;
    }

    /*
    async getFile(url: string): Promise<Blob|Result> {
        let response = await this.getInstance().get<Blob>(url, this.getHeaders(undefined, 'blob'));

        console.log('response from dataservice', response)

        if (response.data.type == 'application/json') {
            return JSON.parse(await response.data.text());
        }

        return response.data;
    }

    async postFile(url: string, data: any): Promise<Blob> {
        let response = await this.getInstance().post(url, data, this.getHeaders(undefined, 'blob'));
        return new Blob([response.data]);
    }
    */

    async post<T>(url: string, data: any): Promise<T> {
        let response = await this.getInstance().post<T>(url, data, this.getHeaders());
        return response.data;
    }

    async put<T>(url: string, data: any): Promise<T> {
        let response = await this.getInstance().put<T>(url, data, this.getHeaders());
        return response.data;
    }

    async getById<T>(url: string, id: number): Promise<T> {
        let response = await this.getInstance().get<T>(url + id, this.getHeaders());
        return response.data;
    }

    /*
    async create<T>(url: string, data: any): Promise<DataResult<T>> {
        let response = await this.getInstance().post<DataResult<T>>(url, data, this.getHeaders());
        return response.data;
    }

    async createFile(data: File): Promise<DataResult<FileCreateResultModel>> {

        const formData = new FormData();
        formData.append('file', data);

        let response = await this.getInstance().post<DataResult<FileCreateResultModel>>('api/v1/Files', formData, this.getHeaders());
        return response.data;
    }

    async update<T>(url: string, data: T): Promise<DataResult<T>> {
        let response = await this.getInstance().put<DataResult<T>>(url, data, this.getHeaders());
        return response.data;
    }

    async updateCollection<T>(url: string, data: T[]): Promise<DataResult<T>[]> {
        let response = await this.getInstance().put<DataResult<T>[]>(url, data, this.getHeaders());
        return response.data;
    }


    async updateTableData<T>(url: string, data: T[]): Promise<Result[]> {
        let response = await this.getInstance().put<Result[]>(url, data, this.getHeaders());
        return response.data;
    }

    async delete(url: string, id: number): Promise<Result> {
        let response = await this.getInstance().delete<Result>(url + id, this.getHeaders());
        return response.data;
    }
    */

}
export default new DataService();