import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

const NavMenuView: React.FC = () => {

    const [collapsed, setCollapsed] = useState(true)

    function toggleNavbar() {
        setCollapsed(x => !x);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3" container light>
                <NavbarBrand tag={Link} to="/">XBit Consulting B.V.</NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/nl/profile"><i className="fa fa-user-circle"></i> Profile</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/nl/projects"><i className="fa fa-briefcase"></i> Projects</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/nl/skills"><i className="fa fa-briefcase"></i> Skills</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/login"><i className="fa fa-unlock"></i> Login</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
}
export default NavMenuView


