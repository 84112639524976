import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import DataService from "../data.service";
import Skill from '../models/skill';

const ProjectsView: React.FC = () => {
    const [methodieken, setMethodieken] = useState<Skill[]>([]);
    const [technologieen, setTechnologieen] = useState<Skill[]>([])

    useEffect(() => {
        DataService
            .get<Skill[]>('skills/methodieken')
            .then(response => setMethodieken(response))
            .catch(e => console.log(e));
        DataService
            .get<Skill[]>('skills/technologieen')
            .then(response => setTechnologieen(response))
            .catch(e => console.log(e));

        
    }, [])

    return (
        <Container className='content'>
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th style={{ width: '50%' }}>Methodiek</th>
                        <th style={{ width: '25%' }}>Laatst gebruikt</th>
                        <th style={{ width: '25%' }}>Ervaring</th>
                    </tr>
                </thead>
                <tbody>
                    {methodieken.map(methodiek =>
                        <tr>
                            <td>{methodiek.name}</td>
                            <td>{methodiek.lastUsed}</td>
                            <td>{methodiek.duur}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th style={{ width: '50%' }}>Technologie</th>
                        <th style={{ width: '25%' }}>Laatst gebruikt</th>
                        <th style={{ width: '25%' }}>Ervaring</th>
                    </tr>
                </thead>
                <tbody>
                    {technologieen.map(skill =>
                        <tr>
                            <td>{skill.name}</td>
                            <td>{skill.lastUsed}</td>
                            <td>{skill.duur}</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </Container>
    );
}
export default ProjectsView;