import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Field from './controls/fieldcontrols/field';
import { Container } from 'reactstrap';

const Login: React.FC = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');


    return (
        <Container className='content' style={{ maxWidth:300, minHeight:'auto' }}>
            <Field fieldType={'Textbox'} controlProps={{ labelProps: { text: 'Username' }, name: 'userName', value: userName, onChange: (value) => { setUserName(value) } }} />
            <Field fieldType={'Password'} controlProps={{ labelProps: { text: 'Password' }, name: 'password', value: password, onChange: (value) => { setPassword(value) } }} />
            <Button variant="success" type="button" onClick={() => alert('clicked')} style={{marginTop:12}} >Login</Button>
        </Container>
    );
}
export default Login;
