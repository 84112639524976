import React from 'react'
import { FieldProps } from './FieldTypes'
import Textbox from './textbox'
//import SelectList from './selectlist'
//import MultiSelect from './multiselect';
//import Checkbox from './checkbox'
//import CheckboxList from './checkboxlist'
//import Email from './email'
//import FileUpload from './fileupload'
//import Textarea from './textarea'
//import Number from './number';
//import Decimal from './decimal';
//import Date from './date';
//import Switch from './switch';
//import Percentage from './percentage';
import Password from './password';

export const FieldControl: React.FC<FieldProps> = ({ controlProps, fieldType, visible, disabled, options, cols, rows }): JSX.Element | null => {

    if (visible === false)
        return null;

    switch (fieldType) {
        case 'Textbox':
            return <Textbox {...controlProps} disabled={disabled} />
        //case 'Email':
        //    return <Email {...controlProps} disabled={disabled} />
        case 'Password':
            return <Password {...controlProps} disabled={disabled} />
        //case 'Textarea':
        //    return <Textarea {...controlProps} disabled={disabled} cols={cols} rows={rows} />
        //case 'Number':
        //    return <Number {...controlProps} disabled={disabled} />
        //case 'Decimal':
        //    return <Decimal {...controlProps} disabled={disabled} />
        //case 'Percentage':
        //    return <Percentage {...controlProps} disabled={disabled} />
        //case 'SelectList':
        //    return <SelectList {...controlProps} disabled={disabled} options={options} />
        //case 'MultiSelect':
        //    return <MultiSelect {...controlProps} disabled={disabled} options={options} />
        //case 'Checkbox':
        //    return <Checkbox {...controlProps} labelProps={labelProps} disabled={disabled} />
        //case 'CheckboxList':
        //    return <CheckboxList {...controlProps} disabled={disabled} options={options} />
        //case 'Switch':
        //    return <Switch {...controlProps} disabled={disabled} />
        //case 'FileUpload':
        //    return <FileUpload {...controlProps} disabled={disabled} />
        //case 'Date':
        //    return <Date {...controlProps} disabled={disabled} />

        default:
            return <Textbox {...controlProps} disabled={disabled} />
    }
}
export default FieldControl
